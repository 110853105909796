import React from 'react';
import entrepriseBandeau1 from 'common/assets/images/photo_rfi_bandeau_9.jpg';
import entreprisePhoto1 from 'common/assets/images/photo_rfi_2.jpg';
import { Article, Bandeau, Baniere } from 'common/components/Section';
import styled from 'styled-components';
import tw from 'twin.macro';
import Carousel from 'common/components/Carousel';

export default function Entreprise() {

    const datesSlides = [
        {
            id: 'slide-1',
            renderItem: (
                <Carousel.DateSlide date="1972">
                    <p>Création de Roto France Impression</p>
                </Carousel.DateSlide>
            )
        },
        {
            id: 'slide-2',
            renderItem: (
                <Carousel.DateSlide date="1998">
                    <p>Implantation de Roto France Impression sur le site actuel de Lognes (77)</p>
                </Carousel.DateSlide>
            )
        },
        {
            id: 'slide-3',
            renderItem: (
                <Carousel.DateSlide date="2001">
                    <p>Installation d'une rotative 72 pages en coupe 120 cm</p>
                </Carousel.DateSlide>
            )
        },
        {
            id: 'slide-4',
            renderItem: (
                <Carousel.DateSlide date="2005">
                    <p>Acquisition de Roto France Impression par le groupe Maury Imprimeur</p>
                </Carousel.DateSlide>
            )
        },
        {
            id: 'slide-5',
            renderItem: (
                <Carousel.DateSlide date="2012">
                    <p>Installation d'une rotative 80 pages en coupe 124 cm<br />
                        Ajout d'un groupe vernis UV et d'une 5ème couleur sur la Rotoman</p>
                </Carousel.DateSlide>
            )
        },
        {
            id: 'slide-6',
            renderItem: (
                <Carousel.DateSlide date="2020">
                    <p>Installation d'une presse XL 106 avec CUTSTAR<br />
                    Installation d'une plieuse MBO</p>
                </Carousel.DateSlide>
            )
        }

    ];

    return (
        <>
            <Bandeau gradient>
                <img src={entrepriseBandeau1} alt="" />
            </Bandeau>

            <Article
                id="quelques-mots"
                title="Quelques mots..."
                image={<img src={entreprisePhoto1} alt="" />}
                revert
                $content
            >
                <p>Le cœur de notre activité est l’impression de magazines. Outre la qualité des imprimés que nous réalisons pour nos clients et utilisateurs finaux, le service offert est une composante essentielle de notre force. En nous montrant disponibles et ouverts, grâce à notre service de fabrication ou par l’intermédiaire des visites clients sur les lieux de production, nous contribuons à la pérennité de l’entreprise.
                    <br /><br />
                    Le respect des délais sur des intervalles restreints prouve notre capacité à réagir et à nous adapter. Notre situation géographique, proche de grands axes routiers et aériens, est un atout majeur. Enfin, face à une concurrence toujours plus effrénée, nous restons compétitifs, tout en continuant de réaliser les investissements nécessaires pour faire vivre et progresser l’entreprise.
                </p>
            </Article>

            <Article id="quelques-valeurs" className="bg-grey-200" $content>
                <SMozaic>
                    <h6><span>130</span> collaborateurs</h6>
                    <h2><span>4</span> hectares de site</h2>
                    <h3><span>1 000 t</span> d'encre</h3>
                    <h1><span>40 000 t</span> de papier imprimé</h1>
                    <h4><span>80 000 m²</span> de plaques</h4>
                    <h5><span>10 000 t</span> de papier stocké</h5>
                    <h1><span>75 000</span> palettes</h1>
                </SMozaic>
            </Article>

            <Article id="quelques-dates" title="Quelques dates..." className="relative" $content>
                <SCarousel slides={datesSlides} />
            </Article>

            <Baniere>
                <p>Qualité, service, délais, coûts...</p>
            </Baniere>
        </>
    );
}

const SCarousel = styled(Carousel)`
    height: 200px;
`;

const SMozaic = styled.div`
    ${tw`align-middle w-1/2 mx-auto py-4`}

    >* {
        ${tw`font-thin`}
    }
    h1 {
        ${tw`text-center`}
    }
    h3 {
        ${tw`text-right`}
    }
    h5 {
        ${tw`text-right`}
    }
    h6 {
        ${tw`text-center`}
    }
    span {
        ${tw`font-bold`}
    }
`;