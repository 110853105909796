import faconnagePhoto1 from 'common/assets/images/photo_rfi_3.jpg';
import faconnageBandeau1 from 'common/assets/images/photo_rfi_bandeau_1.jpg';
import { Article, Bandeau, Baniere, Machine } from 'common/components/Section';
import React from 'react';
import { ContactezNous } from './APropos';

export default function Faconnage() {

    return (
        <>
            <Bandeau gradient>
                <img src={faconnageBandeau1} alt="" />
            </Bandeau>

            <Article id="notre-parc-brochure" title="Notre parc brochure..." $content>
                <div className="flex flex-col">
                    <Machine>
                        <h2>Monostar<p></p></h2>
                        <p>
                            <span>1 chaîne de dos carré collé</span>
                        </p>
                        <p>
                            <span>16</span> postes<br />
                            Vitesse <span>5 000 ex/h</span>
                        </p>
                    </Machine>
                    <Machine>
                        <h2>Corona<p></p></h2>
                        <p>
                            <span>1 chaîne de dos carré collé</span>
                        </p>
                        <p>
                            <span>10</span> postes<br />
                            Vitesse <span>12 000 ex/h</span><br />
                            Préchargement robotisé des cahiers<br />
                            Dépose robotisée des produits avec l'aide d'un palettiseur
                        </p>
                    </Machine>
                    <Machine>
                        <h2>Bravo<p></p></h2>
                        <p>
                            <span>2 chaînes de point métal</span>
                        </p>
                        <p>
                            6 postes + margeur colleur + margeur plieur <br />
                            ou 7 postes + margeur plieur de couverture sans possibilité de margeur colleur <br />
                            ou 7 postes + margeur colleur de couverture sans possibilité de margeur plieur<br />
                            Vitesse <span>12 000 ex/h</span>
                        </p>
                    </Machine>
                    <Machine>
                        <h2>MBO<p></p></h2>
                        <p>
                            <span>1 plieuse</span>
                        </p>
                        <p></p>
                    </Machine>
                    <Machine>
                        <h2>Sitma<p></p></h2>
                        <p>
                            <span>1 chaîne de routage avec système Inkjet</span>
                        </p>
                        <p></p>
                    </Machine>
                </div>
            </Article>

            <Baniere>
                <p>Qualité et délais de façonnage toujours respectés !</p>
            </Baniere>

            <Article
                id="nos-prestations"
                title="Nos prestations..."
                image={<img src={faconnagePhoto1} alt="" />}
                $content
            >
                <p>
                    Nous vous assistons dans le choix de votre finition, que vous choisissiez un dos carré ou un dos piqué, nous avons les machines adaptées à votre besoin. Nous savons vous proposer plusieurs types de colles en fonction des spécificités de vos produits.<br /><br />

                    Nous maîtrisons le grecquage et l'endossage pour obtenir le dos que vous désirez pour votre catalogue ou votre magazine.<br /><br />

                    Nous sommes également en mesure de déposer ou de coller des encarts dans vos magazines.<br /><br />

                    <ContactezNous /> sans attendre !
                </p>
            </Article>
        </>
    );
}