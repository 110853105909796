import React from 'react';
import { useTransitionCarousel } from 'react-spring-carousel';
import tw from 'twin.macro';
import styled from 'styled-components';
import { VscChevronLeft } from '@react-icons/all-files/vsc/VscChevronLeft';
import { VscChevronRight } from '@react-icons/all-files/vsc/VscChevronRight';
import { VscChromeMinimize } from '@react-icons/all-files/vsc/VscChromeMinimize';
import classNames from 'classnames';

/**
 * Composant pour l'affichage d'un carousel d'images
 * 
 * Exemple d'utilisation:
 * const slides = [
 *      {
 *          id: 'slide-1',
 *          renderItem: <Carousel.Slide src={img1} caption={<Carousel.Caption left>L'imprimerie rotative<br />haut de gamme<br /><span> la plus proche de Paris !</span></Carousel.Caption>} />
 *      },
 *      {
 *          id: 'slide-2',
 *          renderItem: <Carousel.Slide src={img2} caption={<Carousel.Caption>Qualité et fiabilité<br /><span>d'impression !</span></Carousel.Caption>} />
 *      },
 *      {
 *          id: 'slide-3',
 *          renderItem: <Carousel.Slide src={img3} caption={<Carousel.Caption right>Prestations sur<br /><span>mesure !</span></Carousel.Caption>} />
 *      }
 *  ];
 *
 *  return <Carousel slides={slides} />;
 * 
 * @param props.slides - Tableau des pages du carousel
 * @param props.indicators - Affichage des indicateurs
 * @returns 
 */
function Carousel({ slides, indicators, ...rest }) {
    const [activeSlide, setActiveSlide] = React.useState(1);

    const {
        carouselFragment,
        slideToPrevItem,
        slideToNextItem,
        slideToItem
    } = useTransitionCarousel({
        items: slides,
        withLoop: true
    });

    const goToSlide = i => {
        setActiveSlide(i);
        slideToItem(`slide-${i}`)
    };

    const goToPrevSlide = () => {
        slideToPrevItem();
        setActiveSlide(o => o === 1 ? 4 : o - 1);
    };

    const goToNextSlide = React.useCallback(() => {
        slideToNextItem();
        setActiveSlide(o => o === 4 ? 1 : o + 1);
    }, [slideToNextItem]);

    React.useEffect(() => {
        const timer = setInterval(() => {
            goToNextSlide();
        }, 5000);
        return () => {
            window.clearInterval(timer);
        };
    }, [goToNextSlide]);

    return (
        <SCarousel {...rest}>
            {carouselFragment}
            <SPrevButton onClick={goToPrevSlide}><VscChevronLeft /></SPrevButton>
            <SNextButton onClick={goToNextSlide}><VscChevronRight /></SNextButton>
            {indicators && <Indicators slides={slides} activeSlide={activeSlide} goToSlide={goToSlide} />}
        </SCarousel>
    );
}


const SImageCaption = styled.h1`
    ${tw`absolute text-white text-2xl md:text-8xl`}
    ${props => props.right ? tw`text-right` : props.left ? tw`text-left` : tw`text-center`}

    bottom: 20vh;
    right: 15%;
    left: 15%;

    span {
        ${tw`block text-base md:text-5xl`}
    }
`;

const SImg = tw.img`block w-full h-full object-cover object-center`;
const SSlide = tw.div`w-full h-full`;
/**
 * 
 * @param props.src - Chemin source de l'image de la page 
 * @param props.caption - Texte de la page 
 * @returns 
 */
function ImageSlide({ src, caption }) {
    return (
        <SSlide>
            <SImg src={src} alt="" />
            {caption}
        </SSlide>
    );
}

const SDateSlide = styled(SSlide)`
    ${tw`flex flex-col items-center`}

    p:first-child {
        ${tw`font-bold mb-5`}
    }
    p {
        ${tw`text-xl px-10 lg:px-0`}
    }
`;
function DateSlide({ date, children }) {
    return (
        <SDateSlide>
            <p>{date}</p>
            {children}
        </SDateSlide>
    );
}

const SButton = styled.button`
    ${tw`
        absolute top-0 bottom-0 flex items-center justify-center 
        p-0 
        text-4xl text-grey-light hover:text-grey-dark text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline
    `}
    width: 15%;
`;
const SPrevButton = styled(SButton)`${tw`left-0`}`;
const SNextButton = styled(SButton)`${tw`right-0`}`;

const SIndicators = tw.div`absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4`;
const SIndicator = styled.button`
    ${tw`text-grey-light hover:text-grey-dark text-4xl`}
    
    &.active {
        ${tw`text-grey-dark`}
    }
`;
/**
 * Boutons du bas de carousel pour la navigation dans les slides
 * @param props.slides - Tableau des pages du carousel
 * @param props.activeSlide - Index de la page active
 * @param props.goToSlide - Méthode à appeler en cas de clic sur un des indicateurs
 * @returns 
 */
function Indicators({ slides, activeSlide, goToSlide }) {

    return (
        <SIndicators>
            {slides.map((slide, i) => <SIndicator key={i} className={classNames({ 'active': `slide-${activeSlide}` === slide.id })} type="button" onClick={() => goToSlide(i + 1)}><VscChromeMinimize /></SIndicator>)}
        </SIndicators>
    );
}

const SCarousel = tw.div`overflow-hidden`;

export default Object.assign(Carousel, {
    ImageSlide, DateSlide, ImageCaption: SImageCaption
});