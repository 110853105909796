import { useLocation } from 'react-router-dom';

/**
 * Hook pour déterminer si l'url passée en paramètre correspond à l'url React router
 * @param params.to - url à comparer 
 * @returns 
 */
export default function useMatchUrl(to, noHash) {
    const location = useLocation();

    return `${location.pathname}${noHash ? '' : location.hash}` === `/${to}`;
}