import { GrLinkedin } from '@react-icons/all-files/gr/GrLinkedin';
import mauryLogo from 'common/assets/images/logo_maury.jpg';
import styled from 'styled-components';
import tw from 'twin.macro';

export default function Footer() {

    return (
        <SFooter>
            <div>
                <p>{new Date().getFullYear()} © Tous droits réservés sur le contenu</p>
                <p>
                    <a className="text-4xl" href="https://www.linkedin.com/company/roto-france-impression---maury-imprimeur/" target="BLANK_"><GrLinkedin className="inline mb-2 hover:text-grey-light" /></a>&nbsp;
                    Roto France Impression<br />25 rue de la Maison Rouge<br />77185 Lognes<br />01 60 06 60 00
                </p>
                <a className="underline" href="doc/Conditions%20g%C3%A9n%C3%A9rales%20-%20Roto%20France%20Impression.pdf" target="BLANK_">Conditions générales de vente</a>
            </div>
            <div>
                <a href="http://www.maury-imprimeur.fr/" target="BLANK_"><SLogoMaury alt="Groupe Maury" src={mauryLogo} /></a><br />
            </div>
        </SFooter>
    );
}

const SFooter = styled.footer`
    ${tw`
        bg-grey-dark
        flex justify-around py-10 text-grey-300
        px-2 lg:px-0
    `}

    p {
        ${tw`text-sm py-5`}
    }
`;
const SLogoMaury = styled.img`width: 150px;`;