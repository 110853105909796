import { useNavigate } from 'react-router-dom';
import useScrollTo from 'react-spring-scroll-to-hook';
import React from 'react';

/**
 * Hook pour faciliter la navigation React router.
 * Compatible avec les hash de type #id et smooth scroll
 * @param params.to - url 
 * @param params.cb - (facultatif) callback à appeler  
 * @returns 
 */
export default function useGoTo(to, cb) {
    const navigate = useNavigate();
    const { scrollTo } = useScrollTo({ friction: 30, clamp: true });

    const [clicked, setClicked] = React.useState(false);

    React.useEffect(() => {
        clicked && cb && cb();
        clicked && navigate(to);
    }, [clicked, navigate, to, cb]);

    React.useEffect(() => {
        clicked && setTimeout(() => {
            const [, hash] = to ? to.split('#') : [];
            scrollTo(`#${hash}`, -80);
            setClicked(false);
        }, 500)
    }, [to, clicked, setClicked, scrollTo]);


    return () => {
        if (to.includes('http://') || to.includes('https://')) {
            window.location.href = to; return null;
        }
        
        setClicked(true);
    };
}
