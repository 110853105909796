import img1 from 'common/assets/images/photo_rfi_home_10.jpg';
import img4 from 'common/assets/images/photo_rfi_home_3.jpg';
import img3 from 'common/assets/images/photo_rfi_home_8.jpg';
import img2 from 'common/assets/images/photo_rfi_home_9.jpg';
import Carousel from 'common/components/Carousel';
import React, { useEffect } from 'react';


export default function Presentation() {

    useEffect(() => {
        document.body.classList.add('bg-black');

        return () => {
            document.body.classList.remove('bg-black');
        };
    });

    const slides = [
        {
            id: 'slide-1',
            renderItem: <Carousel.ImageSlide src={img1} caption={<Carousel.ImageCaption left>L'imprimerie rotative<br />haut de gamme<br /><span> la plus proche de Paris !</span></Carousel.ImageCaption>} />
        },
        {
            id: 'slide-2',
            renderItem: <Carousel.ImageSlide src={img2} caption={<Carousel.ImageCaption>Qualité et fiabilité<br /><span>d'impression !</span></Carousel.ImageCaption>} />
        },
        {
            id: 'slide-3',
            renderItem: <Carousel.ImageSlide src={img3} caption={<Carousel.ImageCaption right>Prestations sur<br /><span>mesure !</span></Carousel.ImageCaption>} />
        },
        {
            id: 'slide-4',
            renderItem: <Carousel.ImageSlide src={img4} caption={<Carousel.ImageCaption>Nous sublimons<br /><span>vos impressions !</span></Carousel.ImageCaption>} />
        }

    ];

    return <Carousel className="w-full h-screen" slides={slides} indicators />;
}