import { GrLinkedin } from '@react-icons/all-files/gr/GrLinkedin';
import aproposPhoto1 from 'common/assets/images/photo_rfi_4.jpg';
import aproposBandeau1 from 'common/assets/images/photo_rfi_bandeau_4.jpg';
import CButton from 'common/components/CButton';
import { Article, Bandeau } from 'common/components/Section';
import useGoTo from 'common/hooks/useGoTo';
import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

export default function APropos() {

    return (
        <>
            <Bandeau gradient>
                <img src={aproposBandeau1} alt="" />
            </Bandeau>

            <Article
                id="contact"
                title="Nous contacter"
                image={<img src={aproposPhoto1} className="hidden lg:inline-block" style={{ maxHeight: '900px' }} alt="" />}
                $content
            >
                <NousContacter>
                    <p>
                        Tel: <b>01 60 06 60 00</b>
                    </p>
                    <br />
                    <h4>Service devis</h4>
                    <p>
                        <a href="mailto:devis@rotofrance.fr" className="text-3xl">devis@rotofrance.fr</a>
                    </p>
                    <br />
                    <h4>Directeur Général</h4>
                    <p>
                        <b>Jean-Michel Thibaut</b>
                    </p>
                    <h4>Directrice Générale Adjointe</h4>
                    <p>
                        <b>Morgane Almeida</b>
                    </p>
                    <h4>Directeur Production et Maintenance</h4>
                    <p>
                        <b>Sylvain Roux</b>
                    </p>
                    <h4>Directeur Fabrication et Façonnage</h4>
                    <p>
                        <b>Stéphane Sigas</b>
                    </p>
                    <h4>Directeur Informatique</h4>
                    <p>
                        <b>Xavier Lambros</b>
                    </p>
                    <h4>Responsable Prépresse</h4>
                    <p>
                        <b>Laurent Neuhut</b>
                    </p>
                    <h4>Responsable Logistique</h4>
                    <p>
                        <b>Marcelo Ferreira</b>
                    </p>
                    <br />
                    <h4>Service fabrication</h4>
                    <p>
                        <a href="mailto:fabrication@rotofrance.fr">fabrication@rotofrance.fr</a>
                    </p>
                    <h4>Service comptable</h4>
                    <p>
                        <a href="mailto:comptabilite@rotofrance.fr">comptabilite@rotofrance.fr</a>
                    </p>
                    <br />
                    <a href="https://www.linkedin.com/company/roto-france-impression---maury-imprimeur/" target="BLANK_"><GrLinkedin className="text-grey hover:text-grey-light text-5xl" /></a>
                </NousContacter>
            </Article>

            <Article id="plan-acces" title="Nous localiser" className="bg-grey-200" $content>
                <iframe className="w-full" title="google maps" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2626.3996097967342!2d2.6308774!3d48.8315159!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x42fa9c5ba5fdf4f5!2sRoto+France+Impression!5e0!3m2!1sfr!2sfr!4v1474027536151&amp;z=1" height="450" frameBorder="0" style={{ border: 0 }} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
            </Article>

            <Article id="protection-donnees" title="Protection des données">
                <p>
                    Conformément au règlement général sur la protection des données (RGPD) ainsi qu’à la loi Informatique et Libertés,
                    Roto France Impression prend et a toujours pris les mesures techniques et organisationnelles afin de garantir la confidentialité
                    et la sécurité de l’ensemble des données à caractère personnel et professionnel recueillies.

                    <br /><br />

                    Dans le cadre de notre activité, nous avons mis en place des procédures internes de gestion de la sécurité de l’ensemble des données
                    transitant dans notre système d’information. Ces procédures ont été étudiées et sont adaptées à l’ensemble des données récoltées et traitées.
                    La garantie de la sécurité, de la confidentialité, de l’intégrité ainsi que la disponibilité de l’ensemble de nos données est au coeur de notre politique de sécurité.

                    <br /><br />

                    Nous nous engageons à maintenir cette sécurité en étudiant fréquemment le changement de nos besoins ainsi que la nature des données
                    que nous récoltons pour continuer de garantir la protection de nos données malgré l’évolution des risques.

                    <br /><br />

                    Pour toutes questions vis à vis de cette démarche ou tout autre demande relative à la loi en vigueur, vous pouvez vous renseigner
                    auprès de vos interlocuteurs habituels au sein de la société ou auprès de notre responsable en charge de la protection des données, Marie JAMAIN.
                </p>
            </Article>

            <Article title="Index égalité femmes / hommes" className="bg-grey-200 text-center">
                <a target="BLANK_" href="doc/Déclaration%20égalité%20femmes%20hommes.pdf">Notre déclaration.</a>
            </Article>
        </>
    );
}

const NousContacter = styled.div`
    ${tw`flex flex-col items-center`}
`;

export function ContactezNous({ title = 'Contactez-nous' }) {
    const handleClick = useGoTo('/apropos#contact');

    return <CButton label={title} className="p-0 inline text-blue-800" onClick={handleClick} />;
}