import { safePolygon, useFloating, useHover, useClick, useInteractions } from '@floating-ui/react-dom-interactions';
import classNames from 'classnames';
import useGoTo from 'common/hooks/useGoTo';
import useMatchUrl from 'common/hooks/useMatchUrl';
import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import CButton from './CButton';

const MenuContext = React.createContext();
/**
 * Composant qui facilite la création d'un dropwdown.
 * @param props.className - Classes CSS pour styliser le bouton de dropdown
 * @param props.button - Pour customiser le bouton de dropdown
 * @param props.onHover - Ouverture du Dropdown au survol
 * @param props.children - Contenu du menu
 * @returns 
 */
// function CDropdown({ to, className, button, onHover, rightPopup = false, children }) {
//     const goTo = useGoTo(to);
//     const isActive = useMatchUrl(to, true);

//     const [open, setOpen] = React.useState(false);

//     const { x, y, reference, floating, strategy, context } =
//         useFloating({
//             open,
//             onOpenChange: setOpen,
//             placement: rightPopup ? 'bottom-end' : 'bottom-start'
//         });

//     const { getReferenceProps, getFloatingProps } = useInteractions([
//         useHover(context, {
//             handleClose: safePolygon() // Permet de garder le popup ouvert
//           }),
//     ]);

//     return (
//         <div className={className}>
//             <CButton
//                 className={classNames('h-full', { 'active': isActive })}
//                 onClick={goTo}
//                 innerRef={reference}
//                 {...getReferenceProps({ ref: reference })}
//                 {...button} />
//             {open && <SUl
//                 {...getFloatingProps({
//                     ref: floating,
//                     style: {
//                         position: strategy,
//                         left: x ?? '',
//                         top: y ?? '',
//                     },
//                 })}
//             >
//                 {children}
//             </SUl>}
//         </div>
//     );
// }
function CDropdown({ to, className, button, rightPopup = false, children }) {
    const [open, setOpen] = React.useState(false);
    const handleClick = useGoTo(to);
    const isActive = useMatchUrl(to, true);

    const { x, y, reference, floating, strategy, context } =
        useFloating({
            open,
            onOpenChange: setOpen,
            placement: rightPopup ? 'bottom-end' : 'bottom-start'
        });

    const { getReferenceProps, getFloatingProps } = useInteractions([
        useClick(context, { enabled: window.innerWidth < 1024 }),
        useHover(context, {
            handleClose: safePolygon() // Permet de garder le popup ouvert
        }),
    ]);

    const mobile = window.innerWidth < 1024;
    const touchScreenHD = !mobile && matchMedia('(hover: none)').matches;
    const { ref, ...referenceProps } = touchScreenHD ? {} : getReferenceProps({ ref: reference }); // On sort la ref pour la passer dans innerRef
    const floatingProps = touchScreenHD ? {} : getFloatingProps({
        ref: floating,
        style: {
            position: strategy,
            left: x ?? '',
            top: y ?? '',
        }
    });
    return (
        <MenuContext.Provider value={{ setOpen }}>
            <div className={className}>
                <CButton
                    className={classNames('h-full', { 'active': isActive })}
                    innerRef={reference}
                    onClick={handleClick}
                    {...referenceProps}
                    {...button} />
                {!touchScreenHD && open &&
                    <SUl {...floatingProps}>
                        {children}
                    </SUl>}
            </div>
        </MenuContext.Provider>
    );
}

const SUl = tw.ul`rounded shadow overflow-auto z-50`;

const SItemButton = styled(CButton)`
    ${tw`bg-transparent rounded-none w-full whitespace-nowrap`}
`;
/**
 * Entrée du menu.
 * @returns 
 */
function Item(props) {
    return (
        <li >
            <SItemButton {...props} />
        </li>
    );
}

/**
 * Entrée du menu qui est un lien react router
 * @param params.to - url
 * @returns 
 */
function LinkItem({ to, ...rest }) {
    const active = useMatchUrl(to);
    const { setOpen } = React.useContext(MenuContext);
    const handleClick = useGoTo(to, () => setOpen(false));

    return <Item className={classNames({ 'active': active })} onClick={handleClick} {...rest} />
}


/**
 * Ligne de séparation dans le menu.
 * @returns 
 */
function Divider() {
    return (
        <li>
            <hr className="text-grey-200" />
        </li>
    );
}

export default Object.assign(CDropdown, {
    Divider,
    Item,
    LinkItem
});
