import environnementImg1 from 'common/assets/images/dechets.png';
import logoImprimVert from 'common/assets/images/logo_impriv_vert.png';
import logoPEFC from 'common/assets/images/logo_pefc.png';
import environnementBandeau1 from 'common/assets/images/photo_rfi_bandeau_6.jpg';
import environnementBandeau2 from 'common/assets/images/photo_rfi_bandeau_7.jpg';
import { Article, Bandeau, Baniere } from 'common/components/Section';
import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

export default function Environnement() {

    return (
        <>
            <Bandeau gradient>
                <img src={environnementBandeau1} alt="" />
            </Bandeau>

            <Article id="notre-priorite" title="Un sujet qui nous tient à coeur...">
                <p>
                    En tant qu’imprimeur responsable et en tant qu'industrie classée ICPE, nous sommes conscients de notre impact sur l’environnement.
                    Imprim'Vert depuis 2007, et PEFC depuis 2009, nous restons à l'affut des meilleures solutions en la matière : simples, pratiques, et pérennes, quel que soit le domaine.
                    <br /><br />
                    Comme par exemple... le tri de nos déchets!
                    <br /><br />
                </p>
            </Article>

            <Baniere>
                <p>Plus de 96% de nos déchets partent en filière de recyclage.</p>
            </Baniere>

            <Bandeau>
                <img style={{ 'maxWidth': '1100px' }} className="m-auto" src={environnementImg1} alt="" />
            </Bandeau>

            <Article id="nos-engagements" title="Nos Actions" className="bg-grey-200 py-10 px-10 lg:px-0" $content>
                <SEngagements>
                    <ul>
                        <h4>En matière de Produit / Process</h4>
                        <li>Nous n’utilisons aucun produit CMR Halogéné ni toxique;</li>
                        <li>Nous sommes souvent précurseurs lors des tests de nouvelles technologies (exemple: plaques sans développement en rotative, utilisation des encres blanches, AGV avec navigation SLAM…);</li>
                        <li>Notre dernier investissement est une machine à carbone neutre, avec des encres végétales, un mouillage sans alcool, vernis acrylique (à base aqueuse), et produits à faible volatilité ou sans COV;</li>
                        <li>Nous réalisons régulièrement des campagnes de substitution de produit en nous appuyant sur leur impact et sur le référentiel Blue Angel;</li>
                        <li>Nous offrons la possibilité d'imprimer avec des encres alternatives dites « blanches » contenant moins de 1% de MOAH, compatibles Blue Angel, et des colles dont le test Ingede 12 est 70.</li>
                    </ul>
                    <ul>
                        <h4>En matière d'Énergie</h4>
                        <li>Nous récupérons la chaleur de nos compresseurs pour le chauffage de nos ateliers;</li>
                        <li>Nous avons changé la presque totalité de nos éclairages pour du led;</li>
                        <li>Nous favorisons l’évolution des gammes de matériels avec de meilleurs rendements énergétiques (moteurs IE5);</li>
                        <li>Nous faisons la chasse aux fuites d'air comprimé;</li>
                        <li>Nous faisons des remises à niveau sur nos sécheurs pour réduire nos consommations de gaz.</li>
                    </ul>
                    <ul>
                        <h4>En matière RSE</h4>
                        <li>Nous travaillons avec une ESAT pour l'entretien de nos espaces verts et le tri de nos plaquettes;</li>
                        <li>Nous respectons les droits fondamentaux du code du travail, luttons contre la discrimination, et sommes fiers de notre diversité;</li>
                        <li>Nous participons au tissu économique et social de Marne la Vallée, et sommes en contact avec les partenaires locaux;</li>
                        <li>Nous favorisons dès que possible des partenaires locaux/nationaux.</li>
                    </ul>
                </SEngagements>

                <p className="flex justify-center">
                    Voir&nbsp;<a target="BLANK_" href="doc/Charte%20environnementale%20-%20Roto%20France%20Impression.pdf">notre charte environnementale</a>
                </p>
            </Article>

            <Bandeau>
                <img src={environnementBandeau2} alt="" />
            </Bandeau>

            <Article id="nos-certifications" title="Nos certifications..." bgColor="bg-grey" $content>
                <div className="flex flex-col lg:flex-row items-center justify-center gap-20 lg:gap-40">
                    <SCertification>
                        <img src={logoPEFC} alt="" />
                        <p>
                            Un papier certifié PEFC garantit sa provenance de forêts exploitées durablement.
                            <br />
                            <br />
                            <a target="BLANK_" href="doc/Certificat%20PEFC%20-%20Roto%20France%20Impression.pdf">Télécharger notre certificat</a>
                            <br />
                            <br />
                            <a target="BLANK_" href="https://www.pefc.org/find-certified/certified-certificates?search=roto+france&searchtype=universal_search">Vérifier notre certificat</a>
                            <br />
                            <br />
                            <a target="BLANK_" href="http://www.pefc-france.org/">www.pefc-france.org</a>
                        </p>
                    </SCertification>
                    <SCertification>
                        <img src={logoImprimVert} alt="" />
                        <p>
                            L’ensemble des déchets dits « dangereux » sont récupérés et traités par des filières conformes à la réglementation.
                            <br />
                            <br />
                            <a target="BLANK_" href="doc/Certificat%20Impriv'Vert%20-%20Roto%20France%20Impression.pdf">Télécharger notre certificat</a>
                            <br />
                            <br />
                            <br />
                            <a target="BLANK_" href="http://www.imprimvert.fr/">www.imprimvert.fr</a>
                        </p>
                    </SCertification>
                </div>
            </Article>
        </>
    );
}

const SEngagements = styled.div`
    ${tw`grid grid-cols-1 lg:grid-cols-3 gap-10 lg:gap-20 text-justify`}

    ul {
        ${tw`list-disc px-5 lg:p-0`}
        
        h4 {
            ${tw`mb-4 font-medium`}
        }
        
        li {
            ${tw`mb-4`}
        }
    }
`;

const SCertification = styled.div`
    ${tw`bg-white p-5`}
    
    width: 300px;
    height: 480px;
    
    img {
        ${tw`mx-auto my-4`}
        
        height: 170px;
    }

    >p {
        ${tw`text-center`}
    }
    
    p a, p a:visited {
        ${tw`text-green-700`}
    }
`;