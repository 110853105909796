import { GrLinkedin } from '@react-icons/all-files/gr/GrLinkedin';
import { VscMenu } from '@react-icons/all-files/vsc/VscMenu';
import blackLongLogo from 'common/assets/images/logo.png';
import whiteLongLogo from 'common/assets/images/logo_blanc.png';
import useGoTo from 'common/hooks/useGoTo';
import React from 'react';
import { useMatch } from 'react-router-dom';
import styled, { css } from 'styled-components';
import tw from 'twin.macro';
import CButton from './CButton';
import CDropdown from './CDropdown';

export default function Header({ stickyHeader }) {

    const firstPage = useMatch({
        path: '/',
        end: true
    });
    const handleClick = useGoTo('/');

    return (
        <SHeader firstPage={firstPage} stickyHeader={stickyHeader}>
            <CButton
                icon={<img src={firstPage && !stickyHeader ? whiteLongLogo : blackLongLogo} className="w-3/4 lg:w-full" alt="RFI" />}
                onClick={handleClick}
            />

            <div>
                <SDropdown
                    className="lg:hidden"
                    button={{ icon: <VscMenu className="text-xl" /> }}
                    rightPopup
                    firstPage={firstPage} stickyHeader={stickyHeader}
                >
                    <CDropdown.LinkItem label="Notre entreprise" to="entreprise" />
                    <CDropdown.LinkItem label="Environnement" to="environnement" css={css`&&&{${environnement}}`} />
                    <CDropdown.LinkItem label="Impression" to="impression" />
                    <CDropdown.LinkItem label="Façonnage" to="faconnage" />
                    <CDropdown.LinkItem label="Prépresse" to="prepresse" />
                    <CDropdown.LinkItem label="À propos" to="apropos" />
                    <CDropdown.Divider />
                    <CDropdown.LinkItem label="Production Center" to="http://www.rotofrance.fr/productioncenter/login.html" css={css`&&&{${liens}}`} />
                    <CDropdown.Divider />
                    <CDropdown.LinkItem icon={<GrLinkedin />} label="Notre Linkedin" to="https://www.linkedin.com/company/roto-france-impression---maury-imprimeur/" css={css`&&&{${liens}}`} />
                </SDropdown>
                <SDropdown
                    to="entreprise"
                    className="hidden lg:inline"
                    firstPage={firstPage} stickyHeader={stickyHeader}
                    button={{ label: 'Notre entreprise' }}
                >
                    <CDropdown.LinkItem label="Quelques mots" to="entreprise#quelques-mots" />
                    <CDropdown.LinkItem label="Quelques dates" to="entreprise#quelques-dates" />
                    <CDropdown.LinkItem label="Quelques valeurs" to="entreprise#quelques-valeurs" />
                </SDropdown>
                <SEnvironnementDropdown
                    to="environnement"
                    className="hidden lg:inline"
                    firstPage={firstPage} stickyHeader={stickyHeader}
                    button={{ label: 'Environnement' }}
                >
                    <CDropdown.LinkItem label="Un sujet qui nous tient à coeur" to="environnement#notre-priorite" />
                    <CDropdown.LinkItem label="Nos engagements" to="environnement#nos-engagements" />
                    <CDropdown.LinkItem label="Nos certifications" to="environnement#nos-certifications" />
                </SEnvironnementDropdown>
                <SDropdown
                    to="impression"
                    className="hidden lg:inline"
                    firstPage={firstPage} stickyHeader={stickyHeader}
                    button={{ label: 'Impression' }}
                    onHover
                >
                    <CDropdown.LinkItem label="Notre choix" to="impression#notre-choix" />
                    <CDropdown.LinkItem label="Nos rotatives" to="impression#nos-rotatives" />
                    <CDropdown.LinkItem label="Machine feuille" to="impression#machine-feuille" />
                    <CDropdown.LinkItem label="Nos prestations" to="impression#nos-prestations" />
                </SDropdown>
                <SDropdown
                    to="faconnage"
                    className="hidden lg:inline"
                    firstPage={firstPage} stickyHeader={stickyHeader}
                    button={{ label: 'Façonnage' }}
                    onHover
                >
                    <CDropdown.LinkItem label="Nos machines" to="faconnage#notre-parc-brochure" />
                    <CDropdown.LinkItem label="Nos prestations" to="faconnage#nos-prestations" />
                </SDropdown>
                <SDropdown
                    to="prepresse"
                    className="hidden lg:inline"
                    firstPage={firstPage} stickyHeader={stickyHeader}
                    button={{ label: 'Prépresse' }}
                    onHover
                >
                    <CDropdown.LinkItem label="Nos CTP" to="prepresse#nos-ctp" />
                    <CDropdown.LinkItem label="Notre charte numérique" to="prepresse#notre-charte-numerique" />
                </SDropdown>
                <SDropdown
                    to="apropos"
                    className="hidden lg:inline"
                    firstPage={firstPage} stickyHeader={stickyHeader}
                    button={{ label: 'À propos' }}
                    onHover
                >
                    <CDropdown.LinkItem label="Contactez nous" to="apropos#contact" />
                    <CDropdown.LinkItem label="Plan d'accès" to="apropos#plan-acces" />
                    <CDropdown.LinkItem label="Protection des données" to="apropos#protection-donnees" />
                </SDropdown>
                <SLiensDropdown
                    className="hidden lg:inline"
                    firstPage={firstPage} stickyHeader={stickyHeader}
                    button={{ label: 'Liens' }}
                    onHover
                    rightPopup
                >
                    <CDropdown.LinkItem label="Production Center" to="http://www.rotofrance.fr/productioncenter/login.html" />
                    <CDropdown.Divider />
                    <CDropdown.LinkItem icon={<GrLinkedin />} label="Notre Linkedin" to="https://www.linkedin.com/company/roto-france-impression---maury-imprimeur/" />
                </SLiensDropdown>
            </div>
        </SHeader>
    );
}

const SHeader = styled.header`
    ${tw`w-full flex justify-between items-center opacity-95 z-50`}

    ${props => props.firstPage && !props.stickyHeader ? tw`` : tw`bg-white shadow`}
    ${props => props.stickyHeader ?
        tw`sticky top-0` :
        css`
            ${tw`absolute`}
            top: 50px;

            @media (max-width: 500px) {
                top: 20px;
            }
        `
    }
    height: 80px;
    padding: 10px 3%;

    >div {
        ${tw`flex`}
    }
`;

const SDropdown = styled(CDropdown)`
    ${tw`h-full mx-1`}

    a {
        ${tw`block w-full`}
    }
    .btn {
        ${tw`uppercase text-xs text-black font-semibold hover:text-white hover:bg-black rounded`}
        ${props => props.firstPage && !props.stickyHeader && tw`bg-white`}
        
        &.active {
            ${tw`bg-black text-white`}
        }
    }
    ul {
        ${tw`bg-white`}
        
        .btn {
            ${tw`rounded-none`}
        }
    }
`;

const environnement = css`
    ${tw`text-green-700 hover:bg-green-700`}
    
    &.active {
        ${tw`bg-green-700`}
    }
`;
const SEnvironnementDropdown = styled(SDropdown)`
    .btn {
        ${environnement}
    }
`;

const liens = css`
    ${tw`text-blue-800 hover:bg-blue-800 blur`}
`;
const SLiensDropdown = styled(SDropdown)`
    .btn {
        ${liens}
    }
`;
