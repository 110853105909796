// React 17
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'tailwindcss/tailwind.css';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


// React 18
// import React from 'react';
// import { createRoot } from 'react-dom/client';
// import App from './App';
// import 'tailwindcss/tailwind.css';
 
// createRoot(document.getElementById('root')).render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );