import prepresseBandeau1 from 'common/assets/images/photo_rfi_bandeau_3.jpg';
import { Article, Bandeau, Machine } from 'common/components/Section';
import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

export default function Prepresse() {
    return (
        <>
            <Bandeau gradient>
                <img src={prepresseBandeau1} alt="" />
            </Bandeau>

            <Article
                id="nos-ctp"
                title={<>Nos 3 CTP <span className="text-xl italic font-light">(Computer To Plate)</span>...</>}
                $content
            >
                <Machine>
                    <h2>KODAK Magnus<p>Very Large Format</p></h2>
                    <p className="text-center">
                        <span>24</span> plaques (2 m²) / heure
                    </p>
                </Machine>
            </Article>

            <SCharte id="notre-charte-numerique" title="Notre charte numérique..." className="bg-grey-200">
                <p>
                    Vous pouvez consulter <a target="BLANK_"
                        href="doc/Charte%20num%C3%A9rique%20-%20Roto%20France%20Impression.pdf">notre charte
                        numérique</a> pour obtenir plus d'informations.
                </p>
            </SCharte>
        </>
    );
}

const SCharte = styled(Article)`
    article p {
        ${tw`py-5 text-center`}
    }
`;