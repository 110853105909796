import Header from 'common/components/Header';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Footer from './common/components/Footer';
import PageNotFound from './common/components/PageNotFound';
import APropos from './containers/APropos';
import Entreprise from './containers/Entreprise';
import Environnement from './containers/Environnement';
import Faconnage from './containers/Faconnage';
import Impression from './containers/Impression';
import Prepresse from './containers/Prepresse';
import Presentation from './containers/Presentation';

export default function App() {

  const [stickyHeader, setStickyHeader] = useState(false);
  const [, setTransparentHeader] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      setStickyHeader(window.pageYOffset > (window.innerWidth <= 500 ? 20 : 50));
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [setTransparentHeader]);

  return (
    <Router>
      <Header stickyHeader={stickyHeader} />

      <SMain stickyHeader={stickyHeader}>
        <Routes>
            <Route index element={<Presentation />} />
            <Route path="entreprise"  element={<Entreprise />} />
            <Route path="environnement" element={<Environnement />} />
            <Route path="impression" element={<Impression />} />
            <Route path="faconnage" element={<Faconnage />} />
            <Route path="prepresse" element={<Prepresse />} />
            <Route path="apropos" element={<APropos />} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
      </SMain>
      <Footer />
    </Router>
  );
}

const SMain = styled.main`
  ${props => props.stickyHeader && css`
    margin-top: -80px;
  `} // Correction nécessaire au passage du header en position sticky
`;
