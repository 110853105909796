import offsetBandeau1 from 'common/assets/images/photo_rfi_bandeau_2.jpg';
import offsetBandeau2 from 'common/assets/images/photo_rfi_bandeau_5.jpg';
import offsetPhoto1 from 'common/assets/images/photo_rfi_1.jpg';
import feuilleBandeau1 from 'common/assets/images/photo_rfi_heidelberg.jpg';
import { Article, Bandeau, Baniere, Machine } from 'common/components/Section';
import React from 'react';
import { ContactezNous } from './APropos';

export default function Impression() {

    return (
        <>
            <Bandeau gradient>
                <img src={offsetBandeau1} alt="" />
            </Bandeau>

            <Article id="notre-choix" title="Nos choix">
                <p>
                    Depuis de nombreuses années, Roto France Impression a été fidèle au premier fabricant de rotatives offset Allemand, la société Man. <br />
                    Ce choix permet un très haut niveau de qualité d'impression avec une fiabilité dans les réalisations.
                </p>
                <p>
                    Nous l'avons complété, pour la partie feuille, avec un autre parternariat de qualité, celui du constructeur Heidelberg.
                </p>
            </Article>

            <Bandeau style={{ maxHeight: 500 }} className="overflow-hidden">
                <img src={offsetBandeau2} alt="" />
            </Bandeau>

            <Article id="nos-rotatives" title="Nos rotatives" $content>
                <div className="flex flex-col">
                    <Machine>
                        <h2>Rotoman<p>coupe <span>630 mm</span></p></h2>
                        <p>
                            <span>16 pages</span>
                        </p>
                        <p>
                            Laize : <span>965 mm</span><br />
                            Poids max par bobine : <span>1 500 kg</span><br />
                            Ø bobine : <span>1 250 mm</span><br />
                            Ø mandrin : <span>76 mm</span><br />
                            <span>5</span> groupes<br />
                            1 groupe de <span>vernis UV</span> (avec possibilité de réserve simple)<br />
                            1 plieuse ou sortie à plat<br />
                            Collage, rognage possibles<br />
                            Contrôle colorimétrique en ligne (ColorScan)<br />
                            Groupe prefolder pour la réalisation de dépliants
                        </p>
                    </Machine>
                    <Machine>
                        <h2>Lithoman IV<p>coupe <span>1240 mm</span></p></h2>
                        <p>
                            <span>48 pages</span>
                        </p>
                        <p>
                            Laize : <span>1 450 mm</span><br />
                            Ø bobine : <span>1 270 mm</span><br />
                            Ø mandrin : <span>76 mm</span><br />
                            Poids max par bobine : <span>2 400 kg</span><br />
                            4 groupes<br />
                            1 plieuse à 2 sorties<br />
                            Collage, rognage possibles<br />
                            Contrôle et correction densitométrique en ligne (DensiWeb)
                        </p>
                    </Machine>
                    <Machine>
                        <h2>Lithoman IV<p>coupe <span>1200 mm</span></p></h2>
                        <p>
                            <span>64 pages</span><br />
                            65/70 g maxi<br />
                            <span>72 pages</span><br />
                            56/65 g maxi
                        </p>
                        <p>
                            Laize : <span>1 980 mm</span><br />
                            Ø bobine : <span>1 270 mm</span><br />
                            Ø mandrin : <span>76 mm</span><br />
                            Poids max par bobine : <span>3 200 kg</span><br />
                            4 groupes<br />
                            1 plieuse à 2 sorties<br />
                            Collage, rognage possibles et paquet en ligne<br />
                            Contrôle et correction densitométrique en ligne (DensiWeb)
                        </p>
                    </Machine>
                    <Machine>
                        <h2>Lithoman IV<p>coupe <span>1140 mm</span></p></h2>
                        <p>
                            <span>64 pages</span><br />
                            65/70 g maxi<br />
                            <span>72 pages</span><br />
                            56/65 g maxi<br />
                            <span>80 pages</span><br />
                            48/60 g maxi
                        </p>
                        <p>
                            Laize : <span>2 060 mm</span><br />
                            Ø bobine : <span>1 270 mm</span><br />
                            Ø mandrin : <span>76 mm</span><br />
                            Poids max par bobine : <span>3 350 kg</span><br />
                            4 groupes<br />
                            1 plieuse à 2 sorties<br />
                            Collage, rognage possibles en ligne<br />
                            Contrôle et correction densitométrique en ligne (DensiWeb)
                        </p>
                    </Machine>
                    <Machine>
                        <h2>Lithoman IV<p>coupe <span>1240 mm</span></p></h2>
                        <p>
                            <span>64 pages</span><br />
                            65/70 g maxi<br />
                            <span>72 pages</span><br />
                            56/65 g maxi<br />
                            <span>80 pages</span><br />
                            48/60 g maxi
                        </p>
                        <p>
                            Laize : <span>2 060 mm</span><br />
                            Ø bobine : <span>1 270 mm</span><br />
                            Ø mandrin : <span>76 mm</span><br />
                            Poids max par bobine : <span>3 350 kg</span><br />
                            4 groupes<br />
                            1 plieuse à 2 sorties<br />
                            Collage, rognage possibles en ligne<br />
                            Contrôle et correction densitométrique en ligne (DensiWeb)
                        </p>
                    </Machine>
                </div>
            </Article>

            <Bandeau style={{ maxHeight: 500 }} className="overflow-hidden">
                <img src={feuilleBandeau1} alt="" />
            </Bandeau>

            <Article id="machine-feuille" title="Notre machine feuille" $content>
                <div className="flex flex-col">
                    <Machine>
                        <h2>Heidelberg Speedmaster XL106<p>coupe <span>variable</span></p></h2>
                        <p>
                            <span>Feuilles ou bobines</span>
                        </p>
                        <p>
                            Format max feuille : <span>75 cm x 106 cm</span><br />
                            Laize bobine max : <span>1 060 mm</span><br />
                            Ø bobine : <span>1 270 mm</span><br />
                            Ø mandrin : <span>76 mm</span><br />
                            <span>9</span> groupes<br />
                            <span>4 couleurs verso + 5 couleurs recto</span> (ou 9 couleurs recto)<br />
                            Contrôle et correction spectrophotométrique en ligne<br />
                            Vernis acrylique<br />
                            Encre végétale<br />
                            Mouillage sans alcool
                        </p>
                    </Machine>
                </div>
            </Article>

            <Baniere>
                <p>Process industriel pour un très haut niveau de qualité d’impression et de fiabilité !</p>
            </Baniere>

            <Article id="nos-prestations" title="Nos prestations..."
                image={<img src={offsetPhoto1} alt="" />}
                $content
            >
                <p>
                    L’ensemble de nos prestations est réalisé sur le même site.
                    Nous produisons sur une machine numérique de toute petites quantités, sur une machine feuille de petites quantités et sur rotatives de moyennes et grandes séries pour les catalogues et magazines.<br /><br />
                    Nous vous conseillons sur le choix du papier, sur sa qualité, sa main, son grammage en fonction de vos attentes.<br /><br />

                    Nous vous accompagnons pour le choix du format, de la constitution du catalogue, de la couverture ainsi que du type de façonnage.<br /><br />

                    Nous planifions les meilleurs délais pour la réalisation de vos catalogues et magazines.<br /><br />

                    <ContactezNous /> sans attendre !
                </p>
            </Article>
        </>
    );
}