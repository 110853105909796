import tw from 'twin.macro';
import styled from 'styled-components';
import classNames from 'classnames';

const Section = tw.section`w-full`;

/**
 * Bandeau photo
 */
const Bandeau = styled(Section)`
    ${tw`relative p-0`}

    &:after {
        ${props => props.gradient && tw`
            content-[""]
            absolute top-0 bottom-0 left-0 right-0
            bg-gradient-to-b from-black via-transparent to-black
            opacity-70
        `}
    }

    img {
        ${tw`w-full`}
    }
`;

/**
 * Banière avec mots d'accroche
 */
const Baniere = styled(Section)`
    ${tw`bg-grey text-white text-center text-3xl flex justify-center items-center px-5 lg:px-0`}
    height: 250px;
`;

/**
 * Article de type texte, texte + image, contenu divers sur une grille de 4 ou 12 colonnes.
 * 
 * Texte: 
 * <Article id="id" title="Titre">
 *      Mon texte
 * </Article>
 * 
 * Texte + image:
 * <Article 
 *  id="id" 
 *  title="Titre"
 *  image={<img src={import} alt="" />}
 *  content
 *  revert // Pour inverser le texte et l'image
 * >
 *      Mon texte
 * </Article>
 * 
 * Contenu:
 * <Article id="id" title="Titre" content>
 *      Contenu
 * </Article>
 */
const SArticleSection = styled(Section).attrs(props => ({className: `${props.bgColor}` ?? ''}))`
    ${tw`grid grid-cols-1 py-10 px-5 lg:px-0 gap-10 place-items-center`}
    ${props => props.$content ? tw`lg:grid-cols-12` : tw`lg:grid-cols-4`}
    
    >*:first-child {
        ${props => props.revert ? tw`order-last` : tw`lg:col-start-2`}
    }
    
    >*:last-child {
        ${props => props.revert && tw`lg:col-start-2`}
    }
    
    >* {
        ${props => props.$content ? props.isImage ? tw`lg:col-span-5` : tw`lg:col-span-10` : tw`lg:col-span-2`}
    }

    article {
        h1 {
            ${tw`text-center text-2xl lg:text-4xl mb-5 lg:mb-10`}
            ${props => props.bgColor && tw`text-white`}
        }
        p {
            ${tw`text-justify`}
        }       
        a {
            ${tw`underline text-blue-800`}
        }
    }
`;
function Article({title, image, children, ...rest}) {

    return (
        <SArticleSection isImage={image} {...rest}>
            {image}
            <article className={classNames({'w-full': !image})}>
                <h1>{title}</h1>
                {children}
            </article>
        </SArticleSection>
    );
}

/**
 * Présentation des machines sous la forme type de contenu.
 */
const Machine = styled.div`
    ${tw`grid grid-cols-1 lg:grid-cols-3 gap-10 lg:gap-0 items-center justify-items-center py-10`}

    &:not(:last-child) {
       ${tw`border-b border-grey-200`}
    }

    p:nth-child(2) {
        ${tw`my-3`}

        span {
            ${tw`text-red-500 font-bold`}
        }
    }

    h2 {
        p {
            ${tw`text-base text-center font-normal`}
        }
    }

    p span {
        ${tw`font-bold`}
    }

    p:last-child {
        ${tw`w-full`}
    }
`;


export { 
    Article, 
    Bandeau, 
    Baniere, 
    Machine,
    Section 
};
