import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

/**
 * Composant pour simplifier l'écriture des boutons
 * @param props.id - Identifiant
 * @param props.type - Type du bouton : submit | reset | button
 * @param props.icon - Icône de début de bouton
 * @param props.label - Texte du bouton
 * @param props.title - Tooltip du bouton
 * @param props.innerRef - ref à passer, ne peut pas être nommée directement ref du fait de l'imbrication
 * @param props.className - Possibilité de passer des classes directement au bouton
 * @param props.css - Possibilité de styliser le bouton avec du css
 * @param props.textThemed - Pour que le texte ait le style du thème
 * @param props.controlTextThemed - Pour que le texte ait le style de textes de contrôle du thème
 * @returns
 */
export default function CButton({ className, icon, innerRef, ...rest }) {
    return (
        <SButton className={classNames(className)} ref={innerRef} {...rest}>
            {icon}
            {rest.label && <span>{rest.label}</span>}
        </SButton>
    );
}

const SButton = styled.button.attrs(() => (
    {
        type: 'button',
        className: 'btn'
    }))`
    ${props => props.css}

    svg {
        ${props => props.label && tw`mr-1`}
    }
    span {
        ${tw`whitespace-nowrap`}
    }
`;