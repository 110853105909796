import React from 'react';
import useScrollTo from 'react-spring-scroll-to-hook';

export default function PageNotFound() {
    const { scrollTo } = useScrollTo({ friction: 30, clamp: true });

    React.useEffect(() => {
        scrollTo();
    });

    return (
        <div className="bg-grey-200 flex flex-col items-center justify-center h-screen">
            <div className="space-x-2">
                <span className="font-secondary text-blue-800 text-9xl drop-shadow-lg">404</span><span>Page inexistante...</span>
            </div>
        </div>
    );
}